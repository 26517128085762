<template>
  <div
    class="fixed z-20 bg-white shadow pr-2 pl-6 pt-4 rounded-xl"
    style="
      top: 8vh;
      bottom: 10vh;
      right: 146px;
      width: 500px;
      display: flex;
      flex-direction: column;
    "
  >
    <div
      class="flex"
      style="justify-content: space-between; align-items: center"
    >
      <h1
        class="col-start-1 col-end-2 font-bold text-darkblue text-xl font-SourceSansPro"
      >
        {{ $t('notifications.notification') }}
      </h1>
      <div class="flex justify-end col-start-2 items-center"></div>
      <div class="tools flex justify-center">
        <notifications-tools />
        <i
          class="el-icon-close text-2xl cursor-pointer text-darkblue font-semibold mr-4 flex"
          style="align-items: center"
          @click="$emit('close')"
        ></i>
      </div>
    </div>
    <div
      class="col-start-1 col-end-2 flex"
      style="justify-content: space-between; align-items: center"
    >
      <h2 class="mt-3 text-sm text-lightblue font-semibold font-SourceSansPro">
        {{ $t('notifications.types') }}
      </h2>
      <span
        class="uppercase font-SourceSansPro text-xs text-darkblue font-bold relative cursor-pointer mr-4"
        @click="$emit('seen-all')"
        >{{ $t('notifications.markAsRead') }}</span
      >
    </div>
    <div class="col-start-1 col-end-3 mt-2 mb-4">
      <el-select
        class="notify-select"
        :style="{ width: browser ? '448px' : '456px' }"
        v-model="notificationType"
        multiple="multiple"
        collapse-tags="collapse-tags"
        :placeholder="$t('select')"
      >
        <el-option
          v-for="notType in notificationTypes"
          :key="notType.key"
          :label="notType.value"
          :value="notType.key"
        ></el-option>
      </el-select>
      <!-- <div class="w-full bg-divider my-4" style="height: 1px"></div> -->
    </div>
    <div class="overflow-y-auto mb-4 pr-2" ref="scroll-table">
      <el-collapse v-model="activeNames">
        <div
          class="mt-2 py-2 px-6 rounded-md cursor-pointer my-1 border border-normalButton items-center"
          v-for="(notify, index) in notifications"
          :class="notify.isSeen ? 'bg-white' : 'bg-newNotification'"
        >
          <el-collapse-item
            class="pl-2"
            :title="notify.templateName"
            :name="index"
            :key="`key+${index}`"
          >
            <template slot="title">
              <div
                class="w-full"
                @click="$emit('show-false', notify.id)"
                style="
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                "
              >
                <div class="cont truncate" style="width: 260px">
                  <img
                    class="col-start-1 col-end-2"
                    :src="`/static/notification_icons/${notify.templateTypeKey}.svg`"
                  /><span
                    class="text-headerText font-bold leading-tight text-sm ml-4"
                    >{{ notify.templateName }}</span
                  >
                </div>
                <span
                  class="font-bold text-annotationColor text-xs flex justify-end mr-4"
                  >{{ timing(notify.time) }}</span
                >
              </div>
            </template>
            <div class="w-full bg-divider my-4" style="height: 1px"></div>
            <span
              class="text-someGrey font-bold leading-tight text-sm"
              :class="notify.isSeen ? 'bg-white' : 'bg-newNotification'"
              >{{ notify.msg }}</span
            >
          </el-collapse-item>
        </div>
      </el-collapse>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment'
import NotificationsTools from '@/components/notifications/tools.vue'

export default {
  components: {
    NotificationsTools
  },
  data() {
    return {
      notificationType: [],
      firstEl: 0,
      lastEl: 0,
      cellHeight: 100,
      viewportHeight: 0,
      topArray: [],
      totalHeight: 0,
      bufferItems: 0,
      showenMessages: [],
      activeNames: [],
      isCellOpen: true
    }
  },
  methods: {
    handleScroll(e) {
      this.showenMessages = []
      const scrollY = e.target.scrollTop
      this.firstEl = Math.floor(scrollY / this.cellHeight)
      this.lastEl = this.firstEl + this.bufferItems
      this.topArray = this.topArray.map(
        (_, index) => (index + this.firstEl) * this.cellHeight
      )
    },
    timing(time) {
      return moment(time).utc().format('DD HH:mm:ss')
    },
    cellOpen(index, id) {
      console.log('cell open ', this.topArray)
      if (this.showenMessages.includes(id)) {
        this.showenMessages.splice(this.showenMessages.indexOf(id))
        this.topArray = [
          ...this.topArray.slice(0, index),
          this.topArray[index],
          ...this.topArray.slice(index + 1).map((el) => el - 113)
        ]
      } else {
        this.showenMessages.push(id)
        this.topArray = [
          ...this.topArray.slice(0, index),
          this.topArray[index],
          ...this.topArray.slice(index + 1).map((el) => el + 113)
        ]
      }
    }
  },

  mounted() {
    this.viewportHeight = this.$refs['scroll-table'].clientHeight
    this.totalHeight = this.notifications.length * this.cellHeight
    console.log(this.totalHeight)
    this.bufferItems = Math.ceil(this.viewportHeight / this.cellHeight)
    this.topArray = Array.apply(null, Array(this.bufferItems)).map(
      (_, index) => index * this.cellHeight
    )
    this.lastEl = this.bufferItems
  },
  computed: {
    ...mapState('dictionary', {
      notificationTypes: (state) => state.notification_type
    }),
    visibleItems() {
      return this.notifications.slice(this.firstEl, this.lastEl)
    },
    browser() {
      return window.navigator.userAgent.includes('Firefox')
    },
    notifications() {
      return this.notificationType.length === 0
        ? this.$parent.gottenNotifications
        : this.$parent.gottenNotifications.reduce(
            (result, notif) =>
              this.notificationType.includes(notif.templateTypeKey)
                ? [...result, notif]
                : result,
            []
          )
    }
  },
  destroyed() {
    console.log('sok', this.currentPopupMarker)
  }
}
</script>
<style>
.bg-newNotification .el-collapse-item__header {
  background-color: #f7fbff;
}

.bg-newNotification .el-collapse-item__wrap {
  background-color: #f7fbff;
}
</style>
